<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Projeto' : 'Cadastrar Projeto'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="$route.params.id > 0 ? btdelete : null"
    :btback="{}"
    :busy="isloading"
    @clicked-save="validationForm"
    @clicked-delete="confirmDelete"
  >
    <validation-observer ref="projectRules">
      <b-form @submit.prevent>
        <b-tabs pills>
          <!-- GERAL -->
          <b-tab title="Geral" active>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Nome *">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome do projeto"
                    rules="required"
                  >
                    <b-form-input
                      v-model="record.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nome do projeto"
                    />
                    <small v-if="errors[0]" class="error text-danger"
                      >Requerido</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group 
                  label="Empresa *"
                  :disabled="$route.params.id > 0"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Empresa"
                    rules="required"
                  >
                    <vue-autosuggest
                      v-model="queryCompany"
                      :suggestions="companies"
                      :input-props="inputProps"
                      :section-configs="companyConfigs"
                      @input="getCompanies"
                    >
                      <template slot-scope="{ suggestion }">
                        <b-badge
                          class="text-capitalize"
                          size="lg"
                          :style="`background: ${suggestion.item.color}`"
                        >
                          {{ getinitials(suggestion.item) }}
                        </b-badge>
                        {{ suggestion.item.name }}
                      </template>
                    </vue-autosuggest>
                    <small v-if="errors[0]" class="error text-danger"
                      >Requerido</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Template de e-mail *">
                  <validation-provider
                    #default="{ errors }"
                    name="Template de e-mail"
                    rules="required"
                    ><v-select
                      v-if="templates.length > 0"
                      v-model="templateSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="templates"
                      autocomplete="off"
                      :clearable="false"
                    />
                    <small v-if="errors[0]" class="error text-danger"
                      >Requerido</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Data Primeira Aula *">
                  <validation-provider
                    #default="{ errors }"
                    name="Data Primeira Aula"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="first_class_date"
                      placeholder="Data Primeira Aula"
                      class="form-control"
                      :config="config"
                    />
                    <small v-if="errors[0]" class="error text-danger"
                      >Requerido</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Duração *">
                  <validation-provider
                    #default="{ errors }"
                    name="Duração"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="record.lesson_duration"
                      placeholder="Duração"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                    <small v-if="errors[0]" class="error text-danger"
                      >Requerido</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Link de Materiais Audiovisual">
                  <b-form-input
                    v-model="record.audiovisual_materials"
                    placeholder="Link de Materiais Audiovisual"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="2">
                <b-card-text class="mb-0">Notificar Backoffice </b-card-text>
                <b-form-checkbox
                  class="custom-control-success mt-1"
                  name="check-button"
                  switch
                  v-model="record.send_email_backoffice"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>

                <!-- <b-form-group label="E-mail Backoffice"> </b-form-group> -->
              </b-col>

              <b-col lg="2">
                <b-card-text class="mb-0">Notificar Marketing </b-card-text>
                <b-form-checkbox
                  class="custom-control-success mt-1"
                  name="check-button"
                  switch
                  v-model="record.send_email_marketing"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>

                <!-- <b-form-group label="E-mail Backoffice"> </b-form-group> -->
              </b-col>

              <b-col lg="2">
                <b-card-text class="mb-0"
                  >Disponível na Plataforma
                </b-card-text>
                <b-form-checkbox
                  class="custom-control-success mt-1"
                  name="check-button"
                  switch
                  v-model="record.avaliable_platform"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>

                <!-- <b-form-group label="E-mail Backoffice"> </b-form-group> -->
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col v-if="modalClassification.loading">carregando classificações...</b-col>
              <b-col v-else>
                 <strong v-if="!modalClassification.canClassify">Selecione uma empresa para conseguir classificar</strong>
                  <b-button
                    v-else
                    variant="outline-info"
                    size="sm" 
                    @click="modalClassification.isOpen = true"
                    >{{ modalClassification.classificationsRoot.length > 0 ? 'Editar Classificações' : 'Classificar' }}</b-button>
              </b-col>
            </b-row>
            <hr>
            <div  v-if="!modalClassification.loading">
              <span v-if="modalClassification.classificationsRoot.length === 0">Projeto sem classificação</span>
              <b-row v-for="(classification, index) in modalClassification.classificationsRoot" :key="`${index}-${classification.name}`">
                  <b-col md="12"><strong>{{ classification.name }}</strong></b-col>
                  <b-col class="mb-1" v-if="classification.children.length > 0">
                      <b-badge v-for="(child, ichild) in classification.children" :key="`${ichild}-${child.name}`" class="mr-50" variant="light-info">
                        {{ child.name }}
                      </b-badge>
                  </b-col>
                  <b-col v-else class="mb-1">
                      nenhuma classificação
                  </b-col>
              </b-row>
          </div>
          </b-tab>
          <!-- GOOGLE -->
          <b-tab title="Google Calendar">
            <b-row>
              <b-col lg="12">
                <b-form-group label="Google Calendar ID">
                  <b-form-input
                    v-model="record.google_calendar_id"
                    placeholder="Google Calendar ID"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Descrição de Rodapé">
                  <div class="form-label-group">
                    <quill-editor
                      v-model="record.calendar_description_footer"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- YOUTUBE -->
          <b-tab title="YouTube">
            <b-row>
              <b-col lg="auto">
                <b-col>
                  <b-form-group label="Thumbnail">
                    <FileUpload
                      :image="urlImage"
                      width="400"
                      @fileChange="onFileChange"
                    />
                  </b-form-group>
                </b-col>
              </b-col>

              <b-col cols="auto">
                <b-col v-if="companySelected && companySelected.abbreviation">
                  <div class="mb-2" style="width: 320px">
                    <b-card-text class="mb-0"
                      >Criar link primeira aula?</b-card-text
                    >
                    <b-form-checkbox
                      class="custom-control-success mt-1"
                      name="check-button"
                      switch
                      v-model="record.link_first_lesson"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col>
                  <div class="mb-2" style="width: 320px">
                    <b-card-text class="mb-0"
                      >Utilizar status da empresa</b-card-text
                    >
                    <b-form-checkbox
                      class="custom-control-success mt-1"
                      name="check-button"
                      switch
                      v-model="record.youtube_override_company_settings"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-col>

              <Transition>
                <b-col v-if="!record.youtube_override_company_settings">
                  <b-col md="auto">
                    <b-form-group label="Status de criação do link">
                      <span class="font-weight-light">
                        <v-select
                          v-model="defaultVisibility"
                          :options="defaultVisibilityOptions"
                          :clearable="false"
                        />
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col md="auto">
                    <b-form-group label="Status do link de transmissão">
                      <span class="font-weight-light">
                        <v-select
                          v-model="statusYoutubeTransmission"
                          :options="statusYoutubeTransmissionOptions"
                          :clearable="false"
                        />
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col md="auto">
                    <b-form-group label="Tempo troca status youtube">
                      <div class="form-label-group">
                        <flat-pickr
                          v-model="record.time_to_change_youtube_status"
                          placeholder="Duração"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true,
                          }"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                </b-col>
              </Transition>
            </b-row>
            <b-row class="pr-1 pl-1">
              <b-col md="6">
                <b-form-group label="Descrição CTA">
                  <div class="form-label-group">
                    <b-form-textarea
                      rows="5"
                      v-model="record.youtube_description_second"
                      placeholder="Informe uma breve descrição (opcional)"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Descrição">
                  <div class="form-label-group">
                    <b-form-textarea
                      rows="5"
                      v-model="record.youtube_description"
                      placeholder="Informe uma breve descrição (opcional)"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Produtos">
            <b-row v-for="(item, index) in jobTypeProductList" :key="index">
              <b-col md="4" align-self="baseline">
                <hr v-if="index != 0" />
                <label>Tipo de demanda</label>
                <feather-icon
                  size="15" 
                  class="ml-1" 
                  icon="Trash2Icon" 
                  style="cursor: pointer;"
                  @click="removeJobTypeProduct(item.job_type)" />
                <v-select
                  v-model="item.job_type"
                  class="mb-1"
                  :options="unusedJobTypes"
                  :clearable="false"
                >
                  <template #option="data">
                    <div class="d-flex justify-content-between">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>
                </v-select>
              </b-col>
              <b-col v-if="item.job_type">
                <hr v-if="index != 0" />
                <b-row>
                  <b-col cols="3" v-for="(prod, index) in item.products" :key="index">
                    <b-form-checkbox
                      class="custom-control-success mr-3 mb-50"
                      style="vertical-align: auto;"
                      :checked="prod.selected"
                      v-model="prod.selected"
                      name="check-button"
                      switch
                    >
                      {{ prod.name }}
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="m-1" v-if="canAddJobType">
              <b-button class="btn-icon" @click="newJobTypeProducts">
                <feather-icon size="16" icon="PlusIcon" />
              </b-button>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-form>
    </validation-observer>

    <classification-modal
      v-if="modalClassification.isOpen"
      title="Classificar Projeto"
      :company-id="companySelected.id"
      :project-id="record.id"
      :classification-root="modalClassification.classificationsRoot"
      @close="modalClassification.isOpen = false"
      @confirm="onClickConfirm"
    />
  </viewcard--c>
</template>
<script>
import _projectsService from "@/services/projects-service";
import _companyService from "@/services/company-service";
import _templateService from "@/services/notification-template-service";
import _productsService from "@/services/audiovisual/products-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import FileUpload from "@/components/FileUpload.vue";
import jobTypeService from '@/services/audiovisual/job-type-service';

import ClassificationModal from '@/components/shared/modals/classifications/classification-modal.vue'
import { TAB_TYPES } from '@/helpers/constants.js'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FileUpload,
    ClassificationModal
  },
  data() {
    return {
      btedit: { permission: `permission.project.edit` },
      btcreate: { permission: `permission.project.create` },
      btdelete: { permission: `permission.project.delete` },
      isloading: false,
      queryCompany: "",
      queryTemplate: "",
      debounceMilliseconds: 300,
      companySelected: { abbreviation: null },
      timeout: null,
      modalClassification: {
        canClassify: false,
        loading: false,
        isOpen: false,
        classificationsRoot: [],
        classifications: []
      },
      companyConfigs: {
        companies: {
          limit: 20,
          label: "Empresas",
          onSelected: (selected) => {
            this.companySelected = selected.item;
            this.modalClassification.canClassify = true
          },
        },
      },
      defaultVisibility: { label: "Público", value: "0" },
      defaultVisibilityOptions: [
        { label: "Público", value: "0" },
        { label: "Privado", value: "1" },
        { label: "Não Listado", value: "2" },
      ],
      statusYoutubeTransmission: { label: "Público", value: "0" },
      statusYoutubeTransmissionOptions: [
        { label: "Público", value: "0" },
        { label: "Não Listado", value: "2" },
      ],
      inputProps: {
        id: "autocompanies__input_ajax",
        placeholder: "Digite o nome da empresa",
        class: "form-control",
        name: "ajax",
      },
      first_class_date: null,
      record: {
        id: 0,
        name: "",
        lesson_duration: "",
        first_class_date: null,
        youtube_image: "",
        youtube_description: "",
        youtube_description_second: "",
        calendar_description_footer: "",
        company_id: "",
        notificationtemplate_id: "",
        google_calendar_id: "",
        jira_project_id: "",
        link_first_lesson: false,
        send_email_backoffice: false,
        send_email_marketing: false,
        youtube_override_company_settings: true,
        avaliable_platform: false
      },
      companies: [],
      templates: [],
      selectedFile: null,
      urlImage: `${require("@/assets/images/pages/sem-foto.png")}`,
      config: {
        dateFormat: "d-m-Y",
        altFormat: "d/m/Y",
        altInput: true,
        locale: Portuguese,
        minDate: new Date(Date.now()),
      },
      templates: [],
      templateSelected: null,
      products: [],
      jobTypeList: [],
      jobTypeProductList: [],
    };
  },
  watch: {
    first_class_date(newValue) {
      let separate_date = newValue.split("T")[0];
      let dates = separate_date.split("-");
      this.record.first_class_date = `${dates[2]}-${dates[1]}-${dates[0]}`;
    },
  },
  async created() {
    this.getNotification();
    await this.getProducts();
    await this.getJobTypes();
    this.getRecord();
    localize("pt_BR", pt_br);
  },
  methods: {
    newJobTypeProducts() {
      this.jobTypeProductList.push({
        job_type: null,
        products: this.products.map((p) => {
          return {
            name: p.name,
            id: p.id,
            selected: false,
          };
        }),
      });
    },
    removeJobTypeProduct(d) {
      this.jobTypeProductList = this.jobTypeProductList.filter((jtp) => jtp.job_type != d);
    },
    validationForm() {
      this.$refs.projectRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    async getProducts() {
      try {
        let { content } = await _productsService.showProductsAll();
        this.products = content.map((m) => {
          return {
            name: `${m.name}`,
            id: m.id,
            selected: false,
          };
        });
      } catch (error) {
        this.$utils.toastError("Notificação", error);
      }
    },
    async getJobTypes() {
      try {
        let { content } = await jobTypeService.getSimplifiedJobTypes();
        this.jobTypeList = content.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
      } catch (error) {
        this.$utils.toastError("Notificação", error);
      }
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.config.minDate = null;
        this.isloading = true;
        _projectsService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;

            if (this.record.projectProducts?.length > 0) {
              const jobTypeIds = [...new Set(this.record.projectProducts.map((rp) => rp.job_type_id))];

              jobTypeIds.forEach(id => {
                this.jobTypeProductList.push({
                  job_type: this.jobTypeList.find((jt) => jt.value === id),
                  products: this.products.map((p) => {
                    return {
                      name: p.name,
                      id: p.id,
                      selected: this.record.projectProducts.some((rp) => rp.product_id === p.id && rp.job_type_id === id),
                    };
                  }),
                });
              });
            }

            if (this.record.company) {
              this.companySelected = {
                id: this.record.company.id,
                name: this.record.company.name,
                color: this.record.company.color,
                abbreviation: this.record.company.abbreviation,
              };
              this.queryCompany = this.companySelected.name;
              this.record.company = null;
              this.modalClassification.canClassify = true
            }

            if (this.record.notificationTemplate) {
              this.templateSelected = {
                value: this.record.notificationTemplate.id,
                label: this.record.notificationTemplate.name,
              };
            }

            if (this.record.youtube_image) {
              this.urlImage = `${res.pathimage}/${this.record.youtube_image}`;
            }

            if (this.record.first_class_date) {
              let separate_date = this.record.first_class_date.split("T")[0];
              let dates = separate_date.split("-");
              this.first_class_date = `${dates[2]}-${dates[1]}-${dates[0]}`;
            }

            this.defaultVisibility =
              this.record.default_visibility === 0
                ? this.defaultVisibilityOptions[0]
                : this.record.default_visibility === 1
                ? this.defaultVisibilityOptions[1]
                : this.defaultVisibilityOptions[2];

            this.statusYoutubeTransmission =
              this.record.youtube_visibility_before_transmission === 0
                ? this.statusYoutubeTransmissionOptions[0]
                : this.statusYoutubeTransmissionOptions[1];


            this.getClassifications()
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    getCompanies() {
      if (this.queryCompany && this.queryCompany.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloading) {
            this.isloading = true;
            _companyService
              .autoComplete(this.queryCompany)
              .then((res) => {
                this.companies = [{ name: "companies", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloading = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    getTemplates() {
      if (this.queryTemplate && this.queryTemplate.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloading) {
            this.isloading = true;
            _templateService
              .autoComplete(this.queryTemplate)
              .then((res) => {
                this.templates = [{ name: "templates", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloading = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    save() {
      if (this.companySelected) {
        this.record.company_id = this.companySelected.id;
      }

      if (this.templateSelected) {
        this.record.notificationtemplate_id = this.templateSelected.value;
      }

      this.record.default_visibility = this.defaultVisibility.value;

      this.record.youtube_visibility_before_transmission =
        this.statusYoutubeTransmission.value;

      let payload = new FormData();

      if (this.selectedFile) {
        payload.append("thumbnail", this.selectedFile);
      }

      this.record.projectProducts = [];
      this.jobTypeProductList.forEach((jtp) => {
        if (jtp.job_type) {
          jtp.products.forEach((p) => {
            if (p.selected) {
              this.record.projectProducts.push({
                product_id: p.id,
                job_type_id: jtp.job_type.value,
              });
            }
          });
        }
      });

      this.record.classifications_inherited = []
      this.record.classifications_extra = []

      if (this.modalClassification.classifications?.length) {
        this.record.classifications_inherited = this.modalClassification.classifications.filter(f => !f.extra).map(m => m.id)
        this.record.classifications_extra = this.modalClassification.classifications.filter(f => f.extra).map(m => m.id)
      }

      payload.append("dados", JSON.stringify(this.record));

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _projectsService.create(payload)
          : _projectsService.update(payload);

      this.isloading = true;
      _createOrUpdate
        .then((res) => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.push({
            path: `/pedagogic/project-view/${res.content.id}`,
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.isloading = true;
      _projectsService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$router.push({
            path: `/pedagogic/projects`,
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getinitials(_item) {
      const ii = _item.name.split(" ");
      if (ii.length >= 2) {
        return ii[0].substring(0, 1) + ii[1].substring(0, 1);
      } else {
        return ii[0].substring(0, 2);
      }
    },
    onFileChange(file) {
      this.selectedFile = file;
      this.urlImage = URL.createObjectURL(file);
    },
    getNotification() {
      _templateService
        .showForType(0)
        .then((res) => {
          this.templates = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    async getClassifications() {
      this.modalClassification.loading = true

      Promise.all([
          _projectsService.getCategoriesForGoals(this.record.company_id),
          _projectsService.getNamesClassifications(this.$route.params.id)
      ]).then(Reqs => {
        if (Reqs[1]?.content?.data?.length) {
          this.modalClassification.classifications = Reqs[1].content.data
        }
        if(this.modalClassification.classifications.length === 0) return

        if (Reqs[0]?.content?.data?.length) {
          this.modalClassification.classificationsRoot = Reqs[0].content.data
        }
        if(this.modalClassification.classificationsRoot.length === 0) return

        this.modalClassification.classificationsRoot.forEach(root => {
          root.children = this.modalClassification.classifications.filter(f => f.parent_id === root.id).map(m => {
            return {
              extra: this.record?.classifications_extra?.some(s => s === m.id),
              ...m
            }
          })
        })

        let rootless = []
        this.modalClassification.classifications.forEach(classification => {
          if (!this.modalClassification.classificationsRoot.some(s => s.id === classification.parent_id)) {
            rootless.push(classification)
          }
        })

        if (rootless.length > 0) {
          this.modalClassification.classificationsRoot.push({
            name: 'Outras',
            id: TAB_TYPES.ROOT_LESS,
            children: rootless
          })
        }
      })
      .catch((error) => this.$utils.toastError("Notificação", error))
      .finally(() => (this.modalClassification.loading = false));
    },
    onClickConfirm({ classificationsRoot, classifications }) {
      this.modalClassification.classificationsRoot = classificationsRoot
      this.modalClassification.classifications =  classifications
      this.modalClassification.isOpen = false
    }
  },
  computed: {
    unusedJobTypes() {
      return this.jobTypeList.filter(
        (jt) =>
          !this.jobTypeProductList.some((jtp) => jtp.job_type != null && jtp.job_type.value === jt.value)
      );
    },
    canAddJobType() {
      return this.unusedJobTypes.length > 0
        && this.jobTypeProductList.every((jtp) => jtp.job_type != null);
    },
  },
};
</script>
<style scoped>
.error {
  position: absolute;
  top: 5px;
  right: 15px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
